import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import i18n from './Plugins/i18n'
import pusher from './Plugins/Pusher'
import ui from '@deliver-up/ui'
import './Plugins'
import { createPinia } from 'pinia'
import resolvePage from 'access/shared/resolvePage'
import '../css/app.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import * as Sentry from '@sentry/vue'
// import * as Sentry from "@sentry/browser";

createInertiaApp({
  progress: { color: 'var(--primary-500)' },
  resolve: resolvePage,
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })

    if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        environment: 'production',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
          Sentry.captureConsoleIntegration(),
          Sentry.captureEvent(),
          Sentry.captureMessage(),
          // Sentry.captureSession(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/butagaz\.dumanage\.com/,
          /^https:\/\/testing\.dumanage\.space/,
          /^https:\/\/butagaz\.dumanage\.space/,
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
      })
    }

    return app
      .component('Link', Link)
      .use(createPinia())
      .use(plugin)
      .use(ui)
      .use(i18n)
      .use(pusher)
      .use(VueSweetalert2)
      .mixin({ methods: { route } })
      .mount(el)
  },
})
